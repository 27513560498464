import { checkAtLeastLength, checkEmailPattern, checkIsFilled, checkIsTrue } from '~utils/input-validator';

const submitFormModel = ({ data }) => {
    return {
      sections: [
        {
          name: 'Step1',
          fields: [
            {
              name: 'name',
              label: '',
              type: 'text',
              placeholder: 'Name',
              validators: [{
                id: 'name-required',
                isValidFun: checkIsFilled,
                alert: 'Please tell us your name'
              }]
            },
            {
              name: 'email',
              label: '',
              type: 'text',
              placeholder: 'Email',
              validators: [{
                id: 'mail-pattern',
                isValidFun: checkEmailPattern,
                alert: 'That doesn\'t seem to be a valid email'
              }, {
                id: 'email-required',
                isValidFun: checkIsFilled,
                alert: 'Please tell us your email'
              }]
            },
            {
              name: 'notes',
              label: '',
              placeholder: 'Type your submission here',
              type: 'textarea',
              validators: [{
                id: 'submission-required',
                isValidFun: checkIsFilled,
                alert: 'Please fill your submission'
              }]
            },
            {
              name: 'categories',
              label: 'Themes (you can choose more than one)',
              placeholder: 'Themes',
              type: 'dropdown',
              options: data.categories.map(c => {
                return { label: c.category_name, value: c.category_name }
              }),
            },
          ]
        },
      ]
    }
}

export default submitFormModel
