import React from "react"
import Layout from "~components/layout"
import SEO from "~components/seo"
import { graphql} from "gatsby"
import RichText from "~components/richText"
import BodyClassName from "react-body-classname"

import styled from "styled-components"
import theme from "~theme/theme"

import { MenuPageWrapper, MenuPageContent, MenuColumns, MenuImage, Person, PersonImage } from "../theme/common"
import Form from '~components/SubmitForm'

import MenuPageSidebar from '~components/MenuPageSidebar'

const SubmitPage = ({ data }) => {

  const formData = {
    categories: data.allSanityTag.nodes.map(node => ({category_name: node.title}))
  }

  return (
    <Layout>
      <SEO title="Submit" />
      <BodyClassName className="info-page">
        <MenuPageWrapper>
          <MenuPageSidebar />
          <MenuPageContent>
            <MenuColumns>
              <div>
                <RichText content={data.sanitySiteSettings._rawShareText}/>
              </div>
              <div>
                <Form data={formData} />
              </div>
            </MenuColumns>
          </MenuPageContent>
        </MenuPageWrapper>
      </BodyClassName>
    </Layout>
  )
}

export const query = graphql`
  {
    allSanityTag {
      nodes {
        title
      }
    }
    sanitySiteSettings(_id: {regex: "/(drafts\\\\.)?siteSettings/"}) {
      _rawShareText
    }
  }
`


export default SubmitPage
