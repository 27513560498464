import React, { useEffect, useState } from 'react'

import Section from './section'
import submitFormModelCallback from './form-data'

const Form = ({
  data,
  ...props
}) => {
  useEffect(() => {
    setSubmitFormModel(submitFormModelCallback({ data }))
  }, [data])

  const [success, setSuccess] = useState(false)
  const [submitError, setSubmitError] = useState(false)

  const [submitFormModel, setSubmitFormModel] = useState(false)

  const submitCallback = e => {
    const sendData = submitFormModel.sections.map(s =>
      s.fields.map(m => encodeURIComponent(m.name)+'='+encodeURIComponent(m.value)).join('&')
    ).join('&')

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: `form-name=submission&${sendData}`
    })
    .then(e => {
      setSuccess(true)
    })
    .catch(error => {
      setSubmitError(error)
    })

  }

  const [stepIndex, setStepIndex] = useState(0)
  const stepCount = submitFormModel && submitFormModel.sections.length
  const updateStepIndex = (index) =>  {
    if (index < stepCount) setStepIndex(index)
    else submitCallback()
  }

  return (
    <form method="post" name="submission" data-netlify="true">
      <input type="hidden" name="form-name" value="submission" />

      {!submitFormModel && 
        <p>Loading...</p>
      }
      {submitFormModel && submitFormModel.sections.map((section, i) =>
        <Section
          visibility={!success}
          key={section.name}
          isCurrent={stepIndex===i}
          isLast={stepIndex===stepCount-1}
          section={section}
          submitCallback={() => updateStepIndex(i+1)}
        />
      )}
      {success &&
        <h2>Thank you for your submission</h2>
      }
      {submitError &&
        <h2>Error: {submitError}</h2>
      }
    </form>
  )
}

export default Form
